import { PageProps } from "gatsby";
import React, { FC } from "react";
import Layout from "../components/layout";
import { SearchBar } from "../components/search-bar";
import Seo from "../components/seo";

const SearchPage: FC<PageProps>=({ location })=>{
  const siteTitle='KIFU PLANET';
  return (
    <Layout location={location} title={siteTitle}>
    <Seo title={siteTitle} lang="ja" description="TODO: KIFU PLANET DESCRIPTION" />
    <div>
      
    </div>
    <SearchBar></SearchBar>
    </Layout>
  )
}

export default SearchPage;