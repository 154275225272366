import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as styles from '../css/search-bar.module.css'


export const SearchBar: FC=()=>{
  const [hasMounted, setHasMounted]=useState(false);

  useEffect(()=>{
    setHasMounted(true);
  }, []);

  if(!hasMounted) return null;

  return (
    <div className={styles.continer}>
      <Helmet>
        <script async src="https://cse.google.com/cse.js?cx=4d206a61d8ae71c60"></script>
      </Helmet>
      <div className="gcse-search"></div>
    </div>
  )
}